import React from 'react';

const Program = () => {
    return (
        <div className='m-p-0'>
            <div className='p-20' style={{background:'rgba(246,246,246,1)',width:'100%!importantan',}}>
                <div className="row">
                    <div className="container row m-p-0 centrar textos">
                        <div className="col-sm-12 col-sd-8 m-p-0 "
                        style={{hborder:'solid green'}}>
                            <div className='container p-20 ' style={{textAlign:'center', }}>
                                <span className='title-green'>Dealer Program</span><br></br> <br></br>
                                <span className='subtitle-negro'>Partner Now</span> <br></br> <br></br>
                                Sunpay by 1814 is committed to helps you make more sales, deliver quality installation services and customer experience.
                                Grown your way of Income by Partnering with SunPay by 1814:
                            </div>
                        </div>
                    </div>
                    <div className="row p-40">
                        <div className="container row m-p-0 textos">
                        <div className='centrar'>
                            <div className="col-sm-12 col-md-4 m-p-0 subtitle-negro centrar" style={{position:'relative'}}>
                                <div id='circle-spinner'></div>
                                <div className="circle p-20 centrar" style={{position:'absolute'}}>
                                    <div className='subtitle-negro'style={{textAlign:'center'}}>Roof Companies</div>
                                </div>
                            </div>
            
                            <div className="col-sm-12 col-md-4 m-p-0 centrar subtitle-negro" style={{position:'relative'}}>
                                <div id='circle-spinner'></div>
                                <div className="circle p-20 centrar" style={{position:'absolute'}}>
                                    <div className='subtitle-negro'style={{textAlign:'center'}}>Home Builders and Realtors</div>
                                </div>
                            </div>
                            
                            <div className="col-sm-12 col-md-4 m-p-0 centrar subtitle-negro" style={{position:'relative'}}>
                                <div id='circle-spinner'></div>
                                <div className="circle p-20 centrar" style={{position:'absolute'}}>
                                    <div className='subtitle-negro'style={{textAlign:'center'}}>Autorized Dealer</div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="container row m-p-0 textos mt-4">
                        <div className="col-sm-12 col-md-12 m-0 p-20 centrar">
                            <span className='subtitle-verde'>Parner whit us!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Program;
import React, { useEffect, useState } from 'react';
import Cargando from './cargando/Cargando';
import Carrera from './carrera/Carrera';
import Collage from './collage/Collage';
import Cliente from './forCliente/Cliente';
import Nosotros from './nosotros/Nosotros';
import Portafolio from './portafolio/Portafolio';
import Program from './program/Program';
import Seccion from './seccion2/Seccion';
import Servicios from './servicios/Servicios';
import Solar from './solar/Solar';
import Testimonios from './Testimonios/Testimonios';
import Ubicacion from './ubicacion/Ubicacion';

const Home = (props) => {
  
    useEffect(()=>{
        //timer para gif
        if(props.showIntro){
            setTimeout(() => {
                props.setShowIntro(false)
            }, 4000);
        }
    },[])
    
    return (
        <section className='row m-p-0'>
            {props.showIntro ? <section id="cargando m-p-0"> <Cargando setShowIntro={props.setShowIntro}></Cargando></section> :
            <>
                <section id="nosotros" className='m-p-0'>
                <Nosotros></Nosotros>
                </section>
                
                <Seccion></Seccion>
                
                <section>
                    <Servicios/>
                </section>

                <section>
                    <Portafolio/>
                </section>

                <section id="program">
                    <Program/>
                </section>

                <section id="carrera">
                    <Carrera/>
                </section>

                <section id="testimonios">
                    <Testimonios/>
                </section>
                
                <section id="solar" >
                    <Solar/>
                </section>
              
                <section id="collage">
                    <Collage/>
                </section>

             
                <section id="servicios m-p-0" >
                    <Ubicacion/>
                    <Cliente/>
                </section>
                <section id="footer m-p-0">
                    <div className='row m-p-0'>
                    
                    </div>
                </section>
            </>
            }
        </section>
        
    );
};

export default Home;
import React from 'react';

const Servicios = () => {
    return (
    <div className='m-p-0'>

<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl">
        <div className="modal-content sombra">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
            style={{position:'absolute', top:0, right:0,}}></button>
            <div className="row m-p-0">
                <div className="col-sm m-p-0 bg-cover"
                style={{background:'url(../../img/banner3_1.jpg), no-repeat', minHeight:'90vh',}}></div>
                <div className="col-sm  m-p-0">
                <div className="m-0 p-60 l-h-1" style={{textAlign:'justify'}}>
                    <p  style={{textAlign:'justify'}}>{/*className='f-s-16 f-w-600' */}
                        Installing a residential solar panels to your property presents many environmental and financial advantages. From reducing your energy bill to protecting your property of power outages.:
                    </p>
                    <h4 className='subtitle-verde' style={{textAlign:'justify'}}>How does Solar Panels Work?</h4>
                    <ul style={{listStyle:'none',}}>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>1.</span> Sun casts light</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>2.</span> Panels absorb light</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>3.</span> Inverter converts to AC Power</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>4.</span> Inverter send power to house meter</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>5.</span> Utility meter sends  and receives power</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}> 
                            <span className='f-w-100'>6.</span> Power is pushed or pulled from grid</li>
                    </ul>
                    
                    <h4 className='subtitle-verde' style={{textAlign:'justify'}}>See if your house Qualify!</h4>
                    <h5 className='subtitle-verde' style={{textAlign:'justify'}}>Solar Batteries:</h5>
                    During unexpected outages  your home will be bright with lights on, keep your food fresh, your house warm.
                    <br></br><br></br>
                    <ul style={{listStyle:'none', textAlign:'justify'}}>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Protect your home from unknown situations</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Best quality Batteries</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Store energy produce by the sun</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Uninterrupted daily activities</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Avoid High Energy rates</li>
                        <li className='f-s-16 f-w-600 l-h-1' style={{textAlign:'justify'}}>● Be in control of your energy</li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdrop2Label" aria-hidden="true">
    <div className="modal-dialog modal-xl">
        <div className="modal-content sombra">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
            style={{position:'absolute', top:0, right:0,}}></button>
            <div className="row m-p-0">
                <div className="col-sm  m-p-0">
                <div className="m-0 p-60 l-h-1">
                    <h4 className='subtitle-verde' style={{textAlign:'justify'}}>The time to upgrade your business is now.</h4>
                    <h5 className='subtitle-verde' style={{textAlign:'justify'}}>Businesses of all sizes will  benefit from owning commercial solar panels.</h5>
                    <p className='f-s-16 l-h-1' style={{textAlign:'justify'}}>
                    Meantime, electricity prices continue to increase year over year. The sooner you decide to go solar, the more your 
                    business  can save.<br></br><br></br>
                    Commercial prices for solar have decreased 58% since 2012.<br></br><br></br>
                    Solar is meant to last for decades, and one of its main advantages is that it can protect your business from 
                    variable and rising fossil fuel-based energy price changes.<br></br><br></br>
                    If you own your solar system there’s usually no money required up front, so you can start to see savings in your 
                    energy bills since day one.
                    </p>
                </div>
                </div>
                <div className="col-sm m-p-0 bg-cover"
                style={{background:'url(../../img/banner2_1.jpg), no-repeat', minHeight:'90vh',}}></div>
            </div>
        </div>
    </div>
</div>

        <div className="row m-p-0">
            <div className='col-12 centrar' style={{padding:'20px 0 10px 0'}}>  
                <div className='title-green' 
                style={{background: '#ffffff', margin:0, padding:'0 25px',}}> Services </div>
            </div>
            <div className="col-sm m-0 p-2">
                <div className='m-p-0 bg-cover' style={{borderTop:'solid 1px #ffffff',background:'url(../../img/banner2.jpg), no-repeat', height:'400px', position:'relative', zIndex:'0',}}>
                &nbsp;
                </div>
                <div style={{marginTop:-150,position:'relative', zIndex:'1'}}>
                    <div className='p-10-L20' style={{ height:'150px',color:"#FFFFFF" }}>{/*background:'rgba(0,0,0,.7)', */}
                        {/*<h5 className='m-p-0 subtitle-blanco l-h-1'>Service </h5>*/}
                        <h4 className='m-p-0 title-blanco inter'>Residencial </h4>
                        <div className="row">
                            {/*<div className="col-8 m-0 p-0 textos" style={{heigth:70, overflow:'hidden', fontWeight:500, textAlign:'justify'}}>
                                The time to upgrade your business is now.
                                Businesses of all sizes will  benefit from owning commercial solar panels.
                            </div>*/}
                            <div className="col-8 m-0 p-0 textos" style={{heigth:70, overflow:'hidden', fontWeight:500, textAlign:'justify'}}>
                            
                            </div>
                            <div className="col-4 m-0 centrar p-10">
                                <div className='btn-green'
                                data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <span className=''> LEARN MORE</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
            <div className="col-sm m-0 p-2">
                <div className='m-p-0 bg-cover' style={{borderTop:'solid 1px #ffffff',background:'url(../../img/banner3.jpg), no-repeat', height:'400px', position:'relative', zIndex:'0',}}>
                &nbsp;
                </div>
                <div style={{marginTop:-150,position:'relative', zIndex:'1'}}>
                    <div className='p-10-L20' style={{ height:'150px',color:"#FFFFFF"}}>{/*background:'rgba(0,0,0,.7)',*/}
                        {/*<h5 className='m-p-0 subtitle-blanco l-h-1'>Service </h5>*/}
                        <h4 className='m-p-0 title-blanco inter'>Comercial</h4>
                        <div className="row">
                            <div className="col-8 m-0 p-0 textos" style={{heigth:70, overflow:'hidden', fontWeight:500, textAlign:'justify',
                        animationDuration: '3s', animationName: 'slidein'}}>
                            
                            </div>
                            <div className="col-4 m-0 centrar p-10">
                                <div className='btn-green'   id="portafolio"
                                data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                                    <span className=''> LEARN MORE</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Servicios;
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Seccion = () => {
    return (
        <div className='p-60'>
            <div className="modal" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title title-green" id="exampleModalLabel">
                                <img className='p-10' src='../../img/sunpay.png' style={{height:'60px', paddingRight:'50px'}}/>
                                Home Efficient
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row m-p-0'>
                                <div className='col-md-8 p-20'>
                                    Energy efficient homes reduce unnecessary energy consumption, greenhouse gas emissions and demands for nonrenewable 
                                    resources. They simultaneously provide healthier living conditions and offer homeowners money savings over 
                                    conventional homes.<br/><br/>
                                    Upgrading your home or business with Solar Panels will equip your property with the he most technological advances 
                                    making it more efficient.<br/><br/>
                                        -Providing  your family the best future, in addition to having a rather futuristic and luxurious look for your home.<br/><br/>
                                        -You can sell unused electricity produced by your  Solar Panels System back to your utility through a 
                                        net-metering arrangement.<br/><br/>
                                        - Monitor your energy compsuntion.
                                </div>
                                <div className='col-md-4 centrar'>
                                    <img className='p-10' src='../../img/homeefficientb.jpg' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <div className='m-p-0 centrar'>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>
                                {/*<div className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings  
                                </div>*/}
                                <div>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings</HashLink>
								</div>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title title-green" id="exampleModalLabel">
                                <img className='p-10' src='../../img/sunpay.png' style={{height:'60px', paddingRight:'50px'}}/>
                                Tax Incentive
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row m-p-0'>
                                <div className='col-md-8 p-20'>
                                The United States federal government subsidizes the purchase and installation of solar power generating 
                                systems with a general  federal solar tax credit.<br/><br/>
                                <b style={{fontSise:'20px'}}>What is the Solar  Tax Incentive?</b><br/><br/>
                                The federal residential solar energy credit is a tax credit that can be claimed on federal income taxes for a 
                                percentage of the cost of a solar photovoltaic (PV) system. 
                                <br/><br/>
                                <b style={{fontSise:'20px'}}>How to know if my house qualify?</b><br/><br/> 
                                1.Your solar PV system was installed between January 1, 2006, and December 31, 2023. The solar PV system is 
                                located at your primary or secondary residence in the United States, or for an off-site community solar 
                                project, if the electricity generated is credited against, and does not exceed, your home’s electricity 
                                consumption. The IRS has permitted a taxpayer to claim a section 25D tax credit for purchase of a portion of a 
                                community solar project.<br/><br/>
                                2.You own the solar PV system.<br/><br/>
                                3. The solar PV system is new or being used for the first time. 
                                Disclaimer: This webpage provides an overview and does not constitute professional tax advice or other 
                                professional financial guidance. It should not be used as the only source of information when making purchasing 
                                decisions, investment decisions, or tax decisions, or when executing other binding agreements.
                                </div>
                                <div className='col-md-4 centrar'>
                                    <img className='p-10' src='../../img/taxincentiveb.jpg' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <div className='m-p-0 centrar'>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>
                                <div>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings</HashLink>
								</div>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title title-green" id="exampleModalLabel">
                                <img className='p-10' src='../../img/sunpay.png' style={{height:'60px',paddingRight:'50px'}}/>
                                Own Your Electricity
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row m-p-0'>
                                <div className='col-md-8 p-20'>
                                En la ventana que dice own your electricity no hay texto se tiene que poner el siguiente:
                                <br/><br/>
                                Adding residential solar panels to your roof  besides is   looking  good it will generate your own electricity with the power of the sun and you will  stop rent power from the grid. 
                                Benefits of OWNING YOUR ELECTRICITY:  
                                <br/><br/>
                                -Home owners can have a peace of mind about energy  peaks,and unpredictable fees and fluctuating prices out of control.
                                -25 Year Production Warranty:  
                                <br/><br/>
                                Solar panels on your roof will produce power on a daily basis, with our 25 year warranty you are totally covered.
                                <br/><br/>
                                <span className='subtitle-verde'>STOP RENT AND START TO OWN YOUR ELECTRICITY!</span>
                                </div>
                                <div className='col-md-4 centrar'>
                                    <img className='p-10' src='../../img/ownelectricityb.jpg' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <div className='m-p-0 centrar'>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>
                                <div>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings</HashLink>
								</div>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title title-green" id="exampleModalLabel">
                            <img className='p-10' src='../../img/sunpay.png' style={{height:'60px',paddingRight:'50px'}}/>
                            Green Ennergy 
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row m-p-0'>
                            <div className='col-md-8 p-20'>
                            <span className='subtitle-verde'>SOLAR IS THE CLEANEST, FASTEST AND  MOST ABUNDANT RENEWABLE SOURCE AVAILABLE.</span>
                            <br/><br/>
                            By choosing Sun Pay by 1814  you say yes to create positive change for the planet producing clean renewable energy.
                            <br/><br/>
                            Clean energy, on the other hand, is 100% pollution-free.
                            <br/><br/>
                            •	Made from unlimited renewable sources<br/><br/>
                            •	Helps preserve and protect the environment for future generations<br/><br/>
                            •	Every megawatt hour (MWh) of U.S. solar energy avoids roughly 1,500 pounds of carbon emissions per years.
                            <br/><br/>
                            You not only purchase your panels also you become part of the solution  to grow the demand for a cleaner and a greener planet.
                                </div>
                                <div className='col-md-4 centrar'>
                                    <img className='p-10' src='../../img/cleanenergyb.jpg' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <div className='m-p-0 centrar'>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>
                                <div>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings</HashLink>
								</div>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="exampleModal5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title title-green" id="exampleModalLabel">
                            <img className='p-10' src='../../img/sunpay.png' style={{height:'60px',paddingRight:'50px'}}/>
                            Home Value Increase 

                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                            <div className='row m-p-0'>
                                <div className='col-md-8 p-20'>
                                    A solar panel system is a valuable and long- last  investment for your home, increasing its value.
                                    Homes with solar energy systems have higher property values than non-solar homes,
                                    Studies show that homes with solar panels sell 20% faster and for 17% more money than those without.
                                    <br/><br/>
                                    <span className='subtitle-verde'>Solar for homes is affordable for every budget:</span>
                                    <br/><br/>
                                    -$0-down:  No upfront costs.<br/><br/>
                                    -Average cost of residential solar continue falling. <br/><br/>
                                    -Residential solar costs are at their lowest in over 30 years. <br/><br/>
                                    -More affordable than ever, offering the best quality and <br/><br/>
                                    -technology advances that maximize power production.<br/><br/>
                                    <span className='subtitle-verde'>CUSTOMIZED PLANS:</span><br/><br/>
                                    Everyone’s home is different, and your energy needs are unique.
                                    That’s why we create custom-designed solar systems for you.
                                </div>
                                <div className='col-md-4 centrar'>
                                    <img className='p-10' src='../../img/homevalueb.jpg' style={{width:'100%'}}/>
                                </div>
                            </div>
                            <div className='m-p-0 centrar'>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>
                                <div>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='col p-20 t-center f-s-16 f-w-600' style={{cursor:'pointer'}}> Check your potential savings</HashLink>
								</div>
                                <div className='col container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}></div>   
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            
            <div className='points' style={{}}> </div>
            <div className='centrar' style={{padding:'40px 0 20px 0'}}>  
                <div className='title-green' 
                style={{background: '#ffffff', margin:0, padding:'40px 25px',}}> 
                    Why Should You Get Solar</div>
            </div>

            <div>
                <div className="container">    
                    <div className="row m-p-0 centrar" style={{width:'100%'}}>
                    
                        <div className="col p-10 solar">
                            <div className='centrar get-solar'>
                                <img className='' src='../../img/homeefficient.jpg' /> 
                            </div>
                            <div className='subtitle-negro t-center p-10-0'>
                                Home<br/>Efficient<br/>
                            </div>
                            <div className='btn-green centrar'
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal1">
                                <span className=''> Read More </span>
                            </div>
                        </div>
                        <div className="col p-10 solar">
                            <div className='centrar get-solar'>
                                <img className='' src='../../img/taxincentive.jpg' /> 
                            </div>
                            <div className='subtitle-negro t-center p-10-0'>
                            Tax<br/>Incentive<br/>
                            </div>
                            <div className='btn-green centrar'
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal2">
                                <span className=''> Read More </span>
                            </div>
                        </div>
                        <div className="col p-10 solar">
                            <div className='centrar get-solar'>
                                <img className='' src='../../img/ownelectricity.jpg' /> 
                            </div>
                            <div className='subtitle-negro t-center p-10-0'>
                            Own Your<br/>Electricity<br/>
                            </div>
                            <div className='btn-green centrar'
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal3">
                                <span className=''> Read More </span>
                            </div>
                        </div>
                        <div className="col p-10 solar">
                            <div className='centrar get-solar'>
                                <img className='' src='../../img/cleanenergy.jpg' /> 
                            </div>
                            <div className='subtitle-negro t-center p-10-0'>
                            Green<br/>Ennergy<br/>
                            </div>
                            <div className='btn-green centrar'
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal4">
                                <span className=''> Read More </span>
                            </div>
                        </div>
                        <div className="col p-10 solar">
                            <div className='centrar get-solar'>
                                <img className='' src='../../img/homevalue.jpg' /> 
                            </div>
                            <div className='subtitle-negro t-center p-10-0'>
                            Home Value<br/>Increase<br/>
                            </div>
                            <div className='btn-green centrar'
                            data-bs-toggle="modal" 
                            data-bs-target="#exampleModal5">
                                <span className=''> Read More </span>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className='p-40'  id="servicios"></div>
            </div>

            <div className='points' style={{}}> </div>
        </div>
    );
};

export default Seccion;
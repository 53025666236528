import './App.css';
import Home from './components/Home'; 
import Menu from './components/menu/Menu';
import Intro from './components/intro/Intro';
import Footer from './components/footer/Footer';
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import { useState } from 'react';

function App() {
	const [showIntro, setShowIntro]= useState(true);

  	return (
    	<Router>
			{/* <Cargando></Cargando> */}
			{!showIntro&&
				<>
					<Menu></Menu>
					<Intro></Intro>
				</>
			}
			<Switch>
				<Route exact path="/">
					<Home showIntro={showIntro} setShowIntro={setShowIntro}/>
				</Route>
			</Switch>
			{!showIntro&&
				<Footer />
			}
        	
		</Router>
  	);
}

export default App;

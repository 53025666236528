import React, { useEffect } from 'react';

const Ubicacion = () => {
    return (
       
        <div className="row m-p-0" style={{background:'#000000'}}>
            
            <div id='ubicacion_partner' className="row">
                <div className="container row m-p-0 centrar textos">
                    
                    <div className="col-sm-6 m-p-0">
                        {/*<div className='col-12 centrar' style={{padding:'20px 0 10px 0',}}>  
                            <div className='title-green' 
                            style={{margin:0, padding:'0 25px',}}> Location </div>
                        </div>*/}    
                        <div className='p-5 f-s-18 textos' style={{color:'#ffffff'}}>
                            {/*}  Our main goal is building long-term business relationships,shared core values, culture and mutual growth. 
                            <br/> -Solar Platform design to scale your business
                            <br/> -Professional Installations   
                            <br/>-Optimize trainings  
                            <br/> - Full project visibility      
                            <br/>-Finance Resources 
                            <br/><br/>*/}           
                            <div className='col-12 centrar' style={{padding:'20px 0 10px 0', borderBottom:'solid #B4DD1F 4px'}}>  
                                <div className='title-green' data-bs-toggle="modal" href="#startModal" role="button"
                                style={{margin:0, padding:'0 25px',}}> Partner with us! </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-sm-6 m-p-0 pt-4 centrar">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8122155.852642509!2d-100.69269827361398!3d31.299598998955766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1665280075993!5m2!1ses-419!2smx" 
                    width="800" height="400" style={{}}  allowFullscreen="" loading="lazy" title="texas map"
                    ></iframe>
                    </div>*/}
                </div>
            </div>
            <div className="col-sm m-0 p-2"></div>
        </div>
    );
};

export default Ubicacion;
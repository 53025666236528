import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Collage = () => {
    return (

        <div>

        <div class="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="container row m-p-0 centrar textos">
                                <div className="col-sm-3 m-p-0 "> 
                                    
                                    <div className="m-p-0 h-100 centrar">
                                        <div className='m-p-0' style={{width:'100%', textAlign:'center',}}>
                                            <p className='title-green-bordo inter'>Gallery</p>
                                            <div className='centrar'
                                            style={{textAlign:'center',margin:0,padding:'20px',}}>
                                                <div className="m-p-0 centrar"
                                                style={{height:'100px', width:'100px',borderRadius:'100%',}}>
                                                    <div className="m-p-0 bg-100"
                                                    style={{height:'100%', width:'100%', background:'url(../../img/logo-sun.png)',}}></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-9 m-p-0"> 
                                    <div className="col-sm-12 m-p-0 ">
                                        <div className="collage">
                                        
                                            <span className='bg-cover centrar' style={{background:'url(../../img/bg-sunpay.png), no-repeat'}}>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#galery">See a picture</button>
                                            </span>
                                            <span className='bg-cover centrar' style={{background:'url(../../img/bg-sunpay.png), no-repeat'}}>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#galery">See a picture</button>
                                            </span>
                                            <span className='bg-cover centrar' style={{background:'url(../../img/bg-sunpay.png), no-repeat'}}>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#galery">See a picture</button>
                                            </span>
                                            <span className='bg-cover centrar' style={{background:'url(../../img/bg-sunpay.png), no-repeat'}}>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#galery">See a picture</button>
                                            </span>
                                            <span className='bg-cover centrar' style={{background:'url(../../img/bg-sunpay.png), no-repeat'}}>
                                                <button type="button" data-bs-toggle="modal" data-bs-target="#galery">See a picture</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                                        </div>*/}
                </div>
            </div>
		</div>
            <div className="modal fade" id="galery" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div className="modal-body modal-lg">
                        <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active" data-bs-interval="10000">
                                <img src="../../img/bg-sunpay.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5 className='subtitle-verde'>Titulo del Proyecto</h5>
                                    <p>Descropcion si tienen</p>
                                </div>
                                </div>
                                <div className="carousel-item" data-bs-interval="2000">
                                <img src="../../img/bg-sunpay.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5 className='subtitle-verde'>Titulo del Proyecto</h5>
                                    <p>Descropcion si tienen</p>
                                </div>
                                </div>
                                <div className="carousel-item">
                                <img src="../../img/bg-sunpay.png" className="d-block w-100" alt="..." />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5 className='subtitle-verde'>Titulo del Proyecto</h5>
                                    <p>Descropcion si tienen</p>
                                </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>

                </div>
                </div>
            </div>
            </div>

            <div id='collage_referer' className="row m-p-0">
                <div className="row">
                    <div className="container row m-p-0 centrar textos">
                        <div className="col-sm-5 centrar m-0 p-40"> 
                            <div className="m-0" style={{padding:'0 40px'}}> 
                                One of the perks of being part of <b>SunPay by 1814 Family</b> is that we love when our satisfied customers start building their network sharing their wonderful stories: 
                                <br></br><br></br>
                                About  how  Pride they are of the investment  of owning solar panels, as Steve Jobs says:  It's really Kind of Cool to have Solar Panels in your Roof”
                                <br></br><br></br>
                                {/**
                                 * <div className=''>
                                    <img src='../../img/testimonios.jpg' style={{height:'60px'}} />
                                    <b>GO TO SEE SECCTION OF TESTIONIAL</b>
                                    <img src='../../img/testimonios.jpg' style={{height:'60px'}} />
                                </div>
                                */}
                                <div className='subtitle-negro centrar'><br></br>REFER US  A FRIEND </div>
                                <div className='subtitle-verde centrar'>AND GET $1000 USD</div>
                                {/**
                                <div className="m-0 centrar p-10">
                                    <div className='btn-green' 
                                    data-bs-toggle="modal" data-bs-target="#galleryModal"
                                    style={{fontSize:'16px', fontWeight:900}}>GO GALLERY 
                                        <span className=''> <FontAwesomeIcon icon={faCheck} style={{fontSize:16,paddingLeft:"5px"}}/> </span>
                                    </div>
                                </div>
                                 */}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Collage;
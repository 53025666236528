import React,{ useState } from 'react';
import axios from '../../axios';

const Entrevista = () => {
    var active = false;
    const [data,setData] = useState({firstName:'',lastName:'',email:'',city:'',state:'',age:'',phone:''})
    
    const _sendMessage = () =>{   
        active = (data.firstName !== "" && data.lastName !== '' && data.email !=='' && data.city !=='' && data.state !=='' 
        && data.age !=='' && data.phone !=='')

        if(!active){
            window.alert("Please fill or fields with your information")
        }
        else{
            axios.post('api/jobinteview',data).then(resp=>{
                if(resp.status === 200){
                    setData({firstName:'',lastName:'',email:'',city:'',state:'',age:'',phone:''})
                }
            }).catch(e=>console.log(e))
        }
    }
    return (
      
        <div class="modal fade" id="entrevistaModal" tabindex="-1" aria-labelledby="entrevistaModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="container row m-p-0 centrar textos">
                                <div className="col-sm-4 m-p-0 "> 
                                    
                                    <div className="m-p-0 h-100 centrar">
                                        <div className='m-p-0' style={{width:'100%', textAlign:'center',}}>
                                            <p className='title-green-bordo inter'>Join us!</p>
                                            <div className='centrar'
                                            style={{textAlign:'center',margin:0,padding:'20px',}}>
                                                <div className="m-p-0 centrar"
                                                style={{height:'100px', width:'100px',borderRadius:'100%',}}>
                                                    <div className="m-p-0 bg-100"
                                                    style={{height:'100%', width:'100%', background:'url(../../img/logo-sun.png)',}}></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 m-p-0">  
                                    <div className="contact-form-wrapper">
                                        
                                        <div id="contact-form" className="contact-form row m-p-0" method="post" role="form">
                                            <div className="col-sm-6 form-group">
                                                <input type="text" placeholder="First Name" className="form-control" name="name" id="name" 
                                                    onChange={(e)=>setData({...data,firstName:e.target.value})}
                                                />
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="text" placeholder="Last Name" className="form-control" name="secondname" id="secondname" 
                                                    onChange={(e)=>setData({...data,lastName:e.target.value})}
                                                />
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="email" placeholder="Your Email" className="form-control" name="email" id="email" 
                                                    onChange={(e)=>setData({...data,email:e.target.value})}
                                                />
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="phone" placeholder="Your phone" className="form-control" name="phone" id="phone" 
                                                onChange={(e)=>setData({...data,phone:e.target.value})}/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="City" placeholder="Your City" className="form-control" name="City" id="City" 
                                                onChange={(e)=>setData({...data,city:e.target.value})}/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="state" placeholder="Your state" className="form-control" name="state" id="state" 
                                                    onChange={(e)=>setData({...data,state:e.target.value})}
                                                />
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="age" placeholder="Your age" className="form-control" name="age" id="age" 
                                                    onChange={(e)=>setData({...data,age:e.target.value})}
                                                />
                                            </div>
                                            
                                            <div id="submit" className="p-20" >
                                                <button type="submit" id="contact-submit" className="btn-green noto" value="Send Message"
                                                data-bs-target="#exampleModalToggle2" 
                                                data-bs-toggle="modal" 
                                                data-bs-dismiss="modal"
                                                onClick={_sendMessage}
                                                > Send a message</button>
                                            </div>
                                            <div className='m-p-0'>
                                                By clicking below, I authorize Sunpay by 1814 to call me and send pre-recorded messages and text messages to me about Sunpay by 1814 products and services at the telephone number I entered above, using an autodialer, even if I am on a national or state “Do Not Call” list. Message and data rates may apply. Maximum 10 texts per month. Consent for calls & texts is optional. You can opt out anytime. You also agree to our Terms of Service
                                            </div>
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                                        </div>*/}
                </div>
            </div>
		</div>
       
    );
};

export default Entrevista;
import React from 'react';

const Nosotros = () => {
    return (
        <div className='row  m-0 p-10'>
            <div className='centrar' style={{padding:'20px 0 10px 0'}}>  
                <div className='title-green' 
                style={{background: '#ffffff', margin:0, padding:'0 25px',}}> 
                </div>
                
            </div>
            
            <div className="col-12 m-0 p-40 bg-100 sun-pay-we">
            <div className='container bg-100'
                style={{height:'100vh', width:'100%', background:'url(../../img/bg-sunpay_2.JPG)',}}></div>
                {/**
                 * <div className='row'>
                    <div className='col-6 inter about-us-text'>
                        We are a <strong>Texans Solar Company</strong> who belives in the incredible power of the sun,
                        <strong>SunPay by 1814</strong> was born from the desire to impact the life of our team members,
                        families, cities, country and out entire planet, making everyday actions that drives us to a brighter
                        future powered by the sun.
                        We have the responsibility to support the revolution in the way we produce the energy to teaming up
                        and becoming part of the solution. At sun pay we are making the
                    </div>
                    <div className='col-6 inter about-us-text'>
                        difference educating with integrity all out customers about the inmensus benefits if choosing
                        solar, saving money and simplifliying the precess that its coming with it.
                        <br/><br/>
                        We are conviced on build a strong connections with all our dealers and partners when conbined
                        with our leadership experience we can all shere the same big picture. At <strong>Sunpay by 1814</strong>
                        we are dreaming on impact every house and let the sun pay your bill.
                    </div>
                    <div className='col-12 centrar'>
                        <img src='../../img/logo-sun.png' alt="sunpay logo" style={{height:50}}/>
                    </div>
                </div>
                 */}
                 <div className="col-12 m-0 p-20 centar" id="valores"></div>
            </div>
            
            <div className="col-12 m-0 p-40 centar" style={{minHeight:'60vh', }}>
                <h4 className='title-green inter centrar'  style={{textAlign:'left'}}> Core Values </h4>
                <p className='textos noto f-w-800 centrar'>
                    Sun Pay by 1814 is a company lead by people ÿor people . That's why we spread out our culture.
                </p>
                
                <div className="accordion" id="accordion">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button centrar" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Quality
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                            <div className="accordion-body">
                            Our commitment is to provide a premium quality solar panels, customer service and in all our products.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button centrar collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Customer SatisfactioN
                            </button>
                            </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                SunPay by 1814 is fucusing on give people beyond the expectation, creating an extraordinary customer
                                experience through all the process. We believe that every detail counts, that's why we are obsessed of
                                taking care on every detail.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button centrar collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                               Innovation
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                We love to make possible what doesn't exist.
                                An each day is an opportunity to create somethinĀ that revolutionaries the way we produce the enerĀy,
                                and impact in our world.
                            </div>
                        </div>
                    </div>
				    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button centrar collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Passion
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                Our team members has a deep love in our daily activities, and transmit passion in every project.
                                They fulfill the ÿunction that is corresponding to perform.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button centrar collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Growth
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordion">
                                <div className="accordion-body">
                                    Always seeking opportunities to become Bigger,better and exploited our boundless ambitions.
                                </div>
                            </div>
                    </div>
                    <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button centrar collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    How Strong Are Solar Panels?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordion">
                                <div className="accordion-body">
                                    Our solar panels are  covered with a high-strength sheet of tempered glass, that is very durable and capable of withstanding d to endure even the worst weather conditions.
                                </div>
                            </div>
                    </div>
                </div>
                <div id='seccion_botones'></div>
            </div>
        </div>
    );
};

export default Nosotros;
import React from 'react';

const Portafolio = () => {
    const imagenes = [0,1,2,3,4,5,6,7];

    return (
        <div className="container p-60">
        
            <div className="modal fade" id="proyects" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="proyectsLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content sombra">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        style={{position:'absolute', top:0, right:0,}}></button>
                        <div className="row m-p-0">
                            <div className="col-sm  m-p-0">
                            <div className="m-0 p-60 l-h-1">
                                <h4 className='subtitle-verde' style={{textAlign:'justify'}}>Title Proyect.</h4>
                                <h5 className='subtitle-negro' style={{textAlign:'justify'}}>littles coments</h5>
                                <p className='f-s-16 l-h-1' style={{textAlign:'justify'}}>
                                Description
                                </p>
                            </div>
                            </div>
                            <div className="col-sm m-p-0"
                            style={{background:'url(../../img/banner2_1.jpg), no-repeat', minHeight:'90vh',}}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2 className='text-center title-negro py-4'>Explore Recent Projects</h2>
                </div>
                {imagenes.map(item=>
                    <div className='col-sm-6 col-lg-3 p-3'>
                        <div className='container-portfolio' data-bs-toggle="modal" data-bs-target="#proyects">
                            <div className='img-wrapper'>
                            <img className="img-fluid" 
                                src={"../../img/Project" + (item+1) + ".jpg"}                          
                                alt="enerzee-portfolio" 
                                style={{borderRadius:5}}   
                            />
                            </div>
                            <div className='portfolio-title '>
                                <div className="details-box">
                                    <div className="consult-details">
                                        <p className="mb-0 link-color f-s-16 f-w-800">Title Proyect.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
               
                

            </div>
        </div>
    );
};

export default Portafolio;
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Intro = () => {
    return (
        <section className='m-p-0 h-100'>
           
           {/*
            <iframe 
            style={{height:'100vh', width:'100%',position:'relative',zIndex:0}}
            src="https://www.youtube.com/embed/eP3LvIRBxmM?autoplay=1&controls=0&rel=0&showinfo=0&mute=1&frameborder=0&enablejsapi=1" 
            title="SUN PAY BY 1814" 
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>*/
           } 
           
           <video style={{width:'100%',height:'100vh', objectFit:'cover',}} autoPlay muted loop>
                <source src="../../img/videosun.mp4" type="video/mp4" style={{width:'100%!important',height:'100%!important', objectFit:'cover',}}></source>
            </video>
        </section>
    );
};

export default Intro;
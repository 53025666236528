import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import axios from '../../axios';

const Cliente = () => {
    var active = false;
    const [data,setData] = useState({firstName:'',lastName:'',address:'',city:'',state:'',cp:'',email:'',phone:'',date:'',time:''})
    
    const _sendMessage = () =>{
        console.log("seending message....",data)
        
        active = (data.firstName !== "" && data.lastName !== '' && data.address !=='' && data.city !=='' && data.state !=='' 
        && data.cp !=='' && data.email !=='' && data.phone !=='' && data.date !=='' && data.time !=='')

        if(!active){
            window.alert("Please fill or fields with your information")
        }
        else{
            axios.post('api/career-plan',data).then(resp=>{
            }).catch(e=>console.log(e))

        }
    }
    return (
        <>
        <div className="modal fade" id="startModal" aria-hidden="true" aria-labelledby="startModalToggleLabel" tabindex="-1">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                        {/**
                         * <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        style={{color:'red', background:'transparent', float:'right', marginTop:'-28px',}}>
                        <FontAwesomeIcon icon={faClose} style={{background:'#a2c128',fontSize:34,padding:"2px 8px", color:'#ffffff', borderRadius:'100%',}}/>
                        </button>
                         */}
                        <div className="row">
                            <div className="container row m-p-0 centrar textos">
                                <div className="col-sm-4 m-p-0 "> 
                                    
                                    <div className="m-p-0 h-100 centrar">
                                        <div className='m-p-0' style={{width:'100%', textAlign:'center',}}>
                                            <p className='title-green-bordo inter'>Free Quote <br></br>Customized Plan!</p>
                                            <div className='centrar'
                                            style={{textAlign:'center',margin:0,padding:'20px',}}>
                                                <div className="m-p-0 centrar"
                                                style={{height:'100px', width:'100px',borderRadius:'100%',}}>
                                                    <div className="m-p-0 bg-100"
                                                    style={{height:'100%', width:'100%', background:'url(../../img/logo-sun.png)',}}></div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 m-p-0">  
                                    <div className="contact-form-wrapper">
                                        <div className="title">
                                            <h4 className='subtitle-negro inter p-20'> Start now with a free customized plan to your home! </h4> 
                                        </div>
                                        
                                        <div id="contact-form" className="contact-form row m-p-0" method="post" role="form">
                                            <div className="col-sm-6 form-group">
                                                <input type="text" placeholder="First Name" className="form-control" name="name" id="name" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="text" placeholder="Last Name" className="form-control" name="secondname" id="secondname" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="adress" placeholder="Your adress" className="form-control" name="adress" id="adress" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="City" placeholder="Your City" className="form-control" name="City" id="City" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="state" placeholder="Your state" className="form-control" name="state" id="state" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="zipe code" placeholder="Your zipe code" className="form-control" name="zipe code" id="City" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="email" placeholder="Your Email" className="form-control" name="email" id="email" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <input type="phone" placeholder="Your phone" className="form-control" name="phone" id="phone" required/>
                                            </div>
                                            <div className="col-sm-6 form-group">
                                                <label className="p-3">Select a date to get a Job Interview</label>
                                                <input type="date" placeholder="Your phone" className="form-control" name="date" id="date" required/>
                                            </div>
                                          
                                            <div id="submit" className="p-20" >
                                                <button type="submit" id="contact-submit" className="btn-green noto" value="Send Message"
                                                data-bs-target="#exampleModalToggle2" 
                                                data-bs-toggle="modal" 
                                                data-bs-dismiss="modal"> Send a message</button>
                                            </div>
                                            <div className='m-p-0'>
                                                By clicking below, I authorize Sunpay by 1814 to call me and send pre-recorded messages and text messages to me about Sunpay by 1814 products and services at the telephone number I entered above, using an autodialer, even if I am on a national or state “Do Not Call” list. Message and data rates may apply. Maximum 10 texts per month. Consent for calls & texts is optional. You can opt out anytime. You also agree to our Terms of Service
                                            </div>
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-20">
                    <h4 className='subtitle-negro inter centrar'>Continue...
                        <button type="button" className="" data-bs-dismiss="modal" aria-label="Close">
                            <div className='btn-green'>
                                <span className=''> <FontAwesomeIcon icon={faCheck} style={{fontSize:24,paddingLeft:"5px"}}/> </span>
                            </div>
                        </button> 
                    </h4>
                    <div className="modal-body centrar" style={{height:200, width:'auto'}}>
                        
                        <div className="letter-image">
                            <div className="animated-mail">
                                <div className="back-fold"></div>
                                <div className="letter">
                                    <div className="letter-border"></div>
                                    <div className="letter-title"></div>
                                    <div className="letter-context"></div>
                                    <div className="letter-stamp">
                                        <div className="letter-stamp-inner"></div>
                                    </div>
                                </div>
                                <div className="top-fold"></div>
                                <div className="body"></div>
                                <div className="left-fold"></div>
                            </div>
                            <div className="shadow"></div>
                        </div>
                    </div>
                    <h4 className='inter centrar'> Messagge senden </h4>
                </div>
            </div>
        </div>
        </>
    );
};

export default Cliente;
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faInstagram,faTwitter,faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Carrera from '../forCarrera/Carrera';


const Menu = () => {
    return (
	<>
		<Carrera></Carrera>
		
		<div className="modal fade m-p-0" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="false"
		style={{zIndex:9999}}>
			<div className="modal-dialog"  style={{ margin:0, padding:0, height:'100%', width:'100%', float:'right',}}>
				<div className="modal-content" style={{background:'none', border:'solid transparent',height:'100%',margin:0, padding:0,}}>
					<div className="modal-body" style={{height:'100%',width:'100%',}}>
						
						<div className="m-p-0 h-100 centrar">
							<div className='m-p-0' style={{width:'100%', textAlign:'center',}}>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
								style={{color:'red', background:'transparent', float:'right', marginTop:'-28px',}}>
									<FontAwesomeIcon icon={faClose} style={{background:'#a2c128',fontSize:34,padding:"2px 8px", color:'#ffffff', borderRadius:'100%',}}/>
								</button>
								<p className='title-green-bordo inter'>GET A FREE SOLAR QUOTE!</p>
								<div className='centrar'
								style={{textAlign:'center',margin:0,padding:'20px',}}>
									<div className="m-p-0 centrar"
									style={{height:'100px', width:'100px',borderRadius:'100%',}}>
										<div className="m-p-0 bg-100"
										style={{height:'100%', width:'100%', background:'url(../../img/logo-sun.png)',}}></div>
									</div>
								</div>
								<div style={{width:'100%',}}>
									<HashLink data-bs-toggle="modal" data-bs-target="#customizedModal"
									className='btn-green noto'> GET STARTER</HashLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className='container-fluid main-menu m-p-0' style={{position:'fixed', zIndex:5}}>

			<button type="button" className="btn-start" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-backdrop="false"
			style={{position:'fixed', rigth:'20px', marginTop:'90vh',zIndex:5}}
			> <img src='../../img/logo-sun.png' style={{heigth:'30px', width:'30px'}} /><br></br>
			START </button>

			<div className='row bg-cover align-items-center' 
			style={{backgroundImage:"url('./../../img/bar-sunpay.jpg')", height:"30px",fontSize:12,fontWeight:'700',textTransform:'uppercase'}}>
				<div className='col col-md-12 menu-contact' style={{textAlign:'right',  fontWeight:'900!important'}}>
					<HashLink className='social-contact' to="/#contact-us" 
					style={{padding:"0 15px", fontWeight:'900'}}>Contact Us</HashLink>
					<span style={{padding:"0 15px", fontWeight:'900'}}>Customer Service: +1 (832) 782 9087</span>
					{/**
					 <a href='' className='social-icon'>
						<FontAwesomeIcon icon={faTwitter} style={{fontSize:14,paddingLeft:"5px"}}/>
					</a>
					*/}
					<a href=''className='social-icon'>
						<FontAwesomeIcon icon={faFacebook} style={{fontSize:14,paddingLeft:"5px",fontWeight:'900'}}/>
					</a>
					<a href='' className='social-icon'>
						<FontAwesomeIcon icon={faInstagram} style={{fontSize:14,paddingLeft:"5px",fontWeight:'900'}}/>
					</a>
					{/** 
					 * <a href='' className='social-icon'>
						<FontAwesomeIcon icon={faLinkedin} style={{fontSize:14,paddingLeft:"5px"}}/>
					</a>
					*/}
				</div>
			</div>
			<div className='col col-md-12 m-p-0'>
				<nav className="navbar navbar-expand-lg navbar-light m-p-0">
					<div className="container-fluid">
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"> <img src=''></img></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarTogglerDemo01">
							<a className="navbar-brand col-2 m-auto" href="/"><img src="../../img/Sunpay-Logo.png" style={{height:'60px'}} /></a>
							<ul className="navbar-nav  mb-2 mb-lg-0 col-10">
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Why Sunpay?
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#nosotros'>- WHO WE ARE?</HashLink></li>
										{/*<li><HashLink  className="dropdown-item" to='/#footer'>- MISSION</HashLink></li>*/}
										<li><HashLink  className="dropdown-item" to='/#valores'>- CORE VALUES</HashLink></li>
										<li><HashLink  className="dropdown-item" to='/#seccion_botones'>- WHY SHOULD YOU GET SOLAR</HashLink></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle  main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Services
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#portafolio'>- RECENT PROJECTS</HashLink></li>
										<li><HashLink  className="dropdown-item" to='/#servicios'>- COMMERCIAL</HashLink></li>
										<li><HashLink  className="dropdown-item" to='/#servicios'>- RESIDENTIAL</HashLink></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle  main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Careers
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#carrera_bonus'>- START A JOURNEY WITH US</HashLink></li>
									</ul>
								</li>
								{/*<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle  main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Roofing
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#footer'>- GET A FREE STIMATED</HashLink></li>
									</ul>
								</li>*/}
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle  main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Dealer Program
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#ubicacion_partner'>- PARTNER NOW</HashLink></li>
										{/*<li><HashLink  className="dropdown-item" to='/#footer'>- PARTNERS VERIFY</HashLink></li>*/}
										
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle  main-menu-link" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Customer Support
									</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
										<li><HashLink  className="dropdown-item" to='/#collage_referer'>- REFERRAL PROGRAM</HashLink></li>
										{/*<li><HashLink  className="dropdown-item" to='/#footer'>- ABOUT SOLAR</HashLink></li>*/}
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link main-menu-link"  role="button" aria-expanded="false"
									data-bs-toggle="modal" data-bs-target="#galleryModal">
										Galery
									</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link main-menu-link"  role="button" aria-expanded="false"
									data-bs-toggle="modal" data-bs-target="#fqsModal">
										FQS
									</a>
								</li>
							</ul>
							
						</div>
					</div>
				</nav>
			</div>	
		</div>  
	</>
    );
};

export default Menu;
import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Testimonios = () => {
    const testimonialsArray = [
        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:5,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },
        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:4,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },
        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:3,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },
        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:1,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },

        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:5,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },
        {
            image:'https://images.unsplash.com/photo-1633332755192-727a05c4013d',
            stars:5,
            date:"3 months ago",
            name:"Roberth Smith",
            testimonial:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor tellus sit amet neque aliquet viverra. Nulla eu leo egestas nulla placerat tincidunt."
        },
    ]

    const _renderStars = (value) =>{
        let stars = []
        for (let index = 0; index < value; index++) {
           stars.push(<FontAwesomeIcon icon={faStar} style={{color:'rgb(231, 113, 27)'}} />)
        } 
        return stars;   
    }

    return (
        <div className='container p-2'>
            <h2 className='m-5 title-negro text-center'>Testimonials</h2>
                <Swiper
                    modules={[Autoplay,Navigation,Pagination]}
                    slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                        }}
                    className="mySwiper"
                >
                {testimonialsArray.map((item)=>
                    <SwiperSlide className='mt-4 mb-5'>
                        <div style={{width:"%100"}}>
                            <div className="card">
                                <div className="card-header p-2">
                                    <div className='row'>
                                        <div className='col col-sm-3'>
                                            <img src={item.image} height="75" width="75" className="rounded-circle" alt="..." />
                                        </div>
                                        <div className='col col-sm-9'>
                                            <p className='f-s-18 m-1'>{item.name}</p>
                                            <p className='f-s-18 m-1'>{_renderStars(item.stars)}</p>
                                            <p className='f-s-12 m-1'>{item.date}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body testimonial-body">
                                    <p className="card-text ">{item.testimonial}.</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )}   
                </Swiper>
            
           
        </div>
    );
};

export default Testimonios;
import React from 'react';

const Cargando = (props) => {
    return (
        <section  className='cargando m-p-0'>
           {/**  * https://codepen.io/schatt3npakt/pen/YzaByjG  
            * https://codepen.io/qasim_hassan/pen/ExQQxPw
            * 
           */}
           <div className="layer centrar row m-p-0"> 
                <div className="col-4 m-0 centrar p-10">
                    <div>
                        <br></br><br></br>
                        <img src="../../img/loading.gif" style={{height:'250px',borderRadius:15}} alt="loading"/> 
                        <br></br><br></br>
                        <div className='col centrar' >
                        
                        {/** 
                         * <div className='col-sm-12 col-md-6 p-40 btn-green'  onClick={()=> props.setShowIntro(false)}>
                            <span className=''> Go </span>
                        </div>
                         */}

                        </div>
                    </div>
                </div>
           </div>
       
        </section>
    );
};

export default Cargando;
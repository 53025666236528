import React from 'react';

const Solar = () => {
    return (
        <div className='m-p-0'>
        
        <div class="modal fade" id="fqsModal" tabindex="-1" aria-labelledby="fqsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div className="container row m-p-0 centrar textos">
                                <div className="col-sm-12 col-sd-8 m-p-0 "
                                style={{hborder:'solid green'}}>
                                    <div className='container p-20 ' style={{textAlign:'center',}}>
                                        <span className='title-green'>Frequent Questions</span><br></br> <br></br><br></br>
                                        {/*<span className='subtitle-negro'>Partner Now</span> <br></br> <br></br><br></br>*/}
                                        Begin with the basics of solar  our main job is to educate or customers before they decide to choose solar.
                                        <br></br><br></br>
                                        <span className='subtitle-negro'>How many Solar Panels do I need to power my home?</span><br></br>
                                        The answer depends on the space available in your roof, the direction on your surface and your household energy. 
                                        <br></br><br></br>
                                        <span className='subtitle-verde'>Does solar panels  still working on a Cloudy days?</span><br></br>
                                        Yes, even on days when the sun isn’t shining, solar panels often continue to produce energy 
                                        <br></br><br></br>
                                        <span className='subtitle-negro'>Does my roof is suitable for solar?</span> <br></br>
                                        Roof that are mostly shaded are not the best candidates for solar. If you plan to replace your roof soon, or you already have an estimated for roof we recommend you to do it before going solar. Also SunPay by 1814 offers bundle packages . ROOF+Solar.
                                        <br></br><br></br>
                                        <div className="container row m-p-0 textos">
                                            <div className="col-sm-12 col-md-12 m-0 p-20 centrar">
                                                <span className='subtitle-verde'>How long is the process?</span>
                                                <span className='subtitle-negro'>&nbsp;&nbsp;Just a couple of weeks.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                                        </div>*/}
                </div>
            </div>
		</div>


            <div className='p-20' style={{background:'rgba(246,246,246,1)',width:'100%!importantan',}}>
                <div className="row">
                    
                    <div className="row centrar p-40">
                        <div className='col row m-p-0'>
                            <div className="col-sm-12 col-md m-p-0 centrar subtitle-blanco'">
                                <div className="circle-verde centrar">
                                    <div className='subtitle-blanco'style={{textAlign:'center', position:'relative', zIndex:2}}> Site Survery</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md m-p-0 centrar"> 
                                <div id="arrowAnim">
                                    <div class="arrowSliding">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay1">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay2">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay3">
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md m-p-0 centrar subtitle-blanco'">
                                <div className="circle-verde centrar">
                                    <div className='subtitle-blanco'style={{textAlign:'center', position:'relative', zIndex:2}}> Permitting</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md m-p-0 centrar"> 
                                <div id="arrowAnim">
                                    <div class="arrowSliding">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay1">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay2">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay3">
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md m-p-0 centrar subtitle-blanco'">
                                <div className="circle-verde centrar">
                                    <div className='subtitle-blanco'style={{textAlign:'center', position:'relative', zIndex:2}}> Installation</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md m-p-0 centrar"> 
                                <div id="arrowAnim">
                                    <div class="arrowSliding">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay1">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay2">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay3">
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md m-p-0 centrar subtitle-blanco'">
                                <div className="circle-verde centrar">
                                    <div className='subtitle-blanco'style={{textAlign:'center', position:'relative', zIndex:2}}> City Inspections</div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md m-p-0 centrar"> 
                                <div id="arrowAnim">
                                    <div class="arrowSliding">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay1">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay2">
                                        <div class="arrow"></div>
                                    </div>
                                    <div class="arrowSliding delay3">
                                        <div class="arrow"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md m-p-0 centrar subtitle-blanco'">
                                <div className="circle-verde centrar">
                                    <div className='subtitle-blanco'style={{textAlign:'center', position:'relative', zIndex:2}}> Utility Connection</div>
                                </div>
                            </div>
                       
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

       
    );
};

export default Solar;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Entrevista from '../forEntrevista/Entrevista';

const Carrera = () => {
    return (
    <div>
    <Entrevista></Entrevista>
        <div className='p-60' style={{background:'rgba(246,246,246,1)',width:'100%!importantan', borderBottom:'solid #000000 20px'}}>
            <div className="row">
                <div className="container row m-p-0 textos">
                    <div className="col-sm-12 m-p-0 "
                        style={{hborder:'solid green'}}>
                        <div className='container p-20 subtitle-negro' style={{textAlign:'center', }}>
                            Start your <span className='subtitle-verde'>journeys</span> with us SunPay by 1814 Family
                            <br></br>
                            <div className='container p-20 ' style={{textAlign:'center', }}>
                                Solar industry is Booming and <span className='subtitle-verde'>Sun Pay</span> by <span className='subtitle-verde'>1814</span> is expanding.
                                <br></br>
                                <span className='subtitle-verde'>Our philosophy</span> is create a working environment where all people feel motivated to give their best.
                                <br></br>
                                Whether you are performance you become part of the solution to build a better future,  through technological embracement called SOLAR PANELS.
                                <br></br>
                                <span className='subtitle-verde'>Discover the endless possibilities to develop your talent and growth with us.</span>
                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>


        <div className="parallax"></div>

        <div id="carrera_bonus" className='Textpalla p-40' style={{borderTop:'solid #000000 20px', borderBottom:'solid #000000 20px'}}>
            <div className='p-60' style={{background:'rgba(246,246,246,1)',width:'100%!importantan',}}>
                <h4 className='subtitle-verde'>
                    Why our members love to be part of our Family:
                </h4>
                <div className="row">
                    <div className="container row m-p-0 textos centrar">
                        <div className="col-sm-8 m-p-0">
                            
                            <ul style={{lineHeight:2}}>
                                <li className='subtitle-texto'>Bonus and Incentives</li>
                                <li className='subtitle-texto'>Optimize Training </li>
                                <li className='subtitle-texto'>Opportunity to grow</li>
                                <li className='subtitle-texto'>Empowering yourself</li>
                                <li className='subtitle-texto'>More effort = more rewards  </li>
                            </ul>
                        </div>
                        <div className="col-sm-4 m-p-0">
                            <div className='container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}>
                                Your career starts here! !
                                <div className="col-4 m-0 centrar p-10">
                                    <div className='btn-green' 
                                    data-bs-toggle="modal" data-bs-target="#entrevistaModal">
                                        <span className=''> <FontAwesomeIcon icon={faCheck} style={{fontSize:24,paddingLeft:"5px"}}/> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="parallax"></div>
            
       
        {/**
         * 
       
        <div className='p-60' style={{background:'rgba(246,246,246,1)',width:'100%!importantan'}}>
            <div className='p-40' style={{ borderTop:'solid 2px #171717', borderBottom:'solid 2px #171717',}}>

                <div className="row">
                    <div className="container row m-p-0 textos">
                        <div className="col-sm-12 m-p-0 "
                            style={{hborder:'solid green'}}>
                            <div className='container p-20 subtitle-negro' style={{textAlign:'rigth', }}>
                                Start your <span className='subtitle-verde'>journeys</span> with us SunPay by 1814 Family
                                <br></br>
                            </div>
                        </div>
                            <div className="col-sm-4 m-p-0">
                                <h4 className='f-s-16 f-w-600'>
                                    Why our members love to be part of our Family
                                </h4>
                                <ul className='f-s-14 f-w-800' style={{lineHeight:2}}>
                                    <li> Bonus and Incentives</li>
                                    <li> Optimize TraininĀ </li>
                                    <li> Opportunity to Ārow</li>
                                    <li> EmpowerinĀ yourselÿ</li>
                                    <li> More effort = more rewards </li>

                                </ul>
                            </div>
                            <div className="col-sm-8 m-p-0 "
                            style={{hborder:'solid green'}}>
                                <div className='container p-20 ' style={{textAlign:'center', }}>
                                    Solar industry is Booming and <span className='subtitle-verde'>Sun Pay</span> by <span className='subtitle-verde'>1814</span> is expanding.
                                    <br></br>
                                    <span className='subtitle-verde'>Our philosophy</span> is create a working environment where all people feel motivated to give their best.
                                    <br></br>
                                    Whether you are performance you become part of the solution to build a better future,  through technological embracement called SOLAR PANELS.
                                    <br></br>
                                    <span className='subtitle-verde'>Discover the endless possibilities to develop your talent and growth with us.</span>
                                </div>
                            </div>
                       
                    </div>
                </div>
                <div className="row p-60" >
                    <div className='container centrar' style={{borderTop:'solid 2px #171717', textAlign:'center', marginTop:'30px', paddingTop:'30px'}}>
                        Your career starts here!
                        <div className="col-4 m-0 centrar p-10">
                            <div className='btn-green' 
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                <span className=''> CONTACT </span>
                            </div>
                        </div>
                    </div>
                </div>

           
            </div>
        </div>
          */}
    </div>
    );
};

export default Carrera;
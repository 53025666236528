import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faInstagram,faTwitter,faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    let year = new Date().getFullYear();
  
    return (
        <div className='container-fluid main-menu p-4 pb-1' >
            <footer className='row mt-4 mb-1 p-4 centrar'>
                <div className='col-md-3 col-sm-12'>
                    <div className='row centrar'>
                        <div className='col col-md-12 text-center'>
                            <h5 className='text-center mt-2 footer-media'>FOLLOW US</h5>
                            {/* <a href='' className='social-icon-footer'>
                                <FontAwesomeIcon icon={faTwitter}/>
                            </a> */}
                            <a href=''className='social-icon-footer'>
                                <FontAwesomeIcon icon={faFacebook}/>
                            </a>
                            <a href='' className='social-icon-footer'>
                                <FontAwesomeIcon icon={faInstagram}/>
                            </a>
                            {/* <a href='' className='social-icon-footer'>
                                <FontAwesomeIcon icon={faLinkedin}/>
                            </a> */}
                        </div>
                        <div className='col col-md-12 mt-4 text-center'>
                            <img src="../../img/Sunpay-Logo.png" style={{maxHeight:40}}/>
                        </div>
                    </div>
                    
								
                </div>
                {/*
                <div className='col-md-10 col-sm-12'>
                    <div className='row'>
                        <div className='col col-md-2 '>
                            <h5 className="footer-tittle">WHY SUNPAY?</h5>
                            <p><HashLink className='footer-link' to="">WHO WE ARE?</HashLink></p>
                            <p><HashLink className='footer-link' to="">MISSION</HashLink></p>
                            <p><HashLink className='footer-link' to="">CORE VALUES</HashLink></p>
                        </div>
                        <div className='col col-md-2'>
                            <h5 className="footer-tittle">SERVICES</h5>
                            <p><HashLink className='footer-link' to="">COMMERCIAL</HashLink></p>
                            <p><HashLink className='footer-link' to="">RESIDENTIAL</HashLink></p>
                        </div>
                        <div className='col col-md-2'>
                            <h5 className="footer-tittle">CAREERS</h5>
                            <p><HashLink className='footer-link' to="">START A JOURNEY WITH US</HashLink></p>
                        </div>
                        <div className='col col-md-2'>
                            <h5 className="footer-tittle">ROOFING</h5>
                            <p><HashLink className='footer-link' to="">GET A FREE STIMATED</HashLink></p>
                        </div>
                        <div className='col col-md-2'>
                            <h5 className="footer-tittle">DEALER PROGRAM</h5>
                            <p><HashLink className='footer-link' to="">PARTNER NOW</HashLink></p>
                            <p><HashLink className='footer-link' to="">PARTNERS VERIFY</HashLink></p>
                        </div>
                        <div className='col col-md-2'>
                            <h5 className="footer-tittle">CUSTOMER SUPPORT</h5>
                            <p><HashLink className='footer-link' to="">REFERRAL PROGRAM</HashLink></p>
                            <p><HashLink className='footer-link' to="">ABOUT SOLAR</HashLink></p>
                        </div>
                    </div>
                </div>
                */}
            </footer>
            <p className='footer-botom text-center mt-4 mb-1'>©{year} Sunpay by 1814. All rights reserved.</p>
        </div>
    );
};

export default Footer;